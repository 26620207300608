import React, { useCallback, useEffect, useState } from 'react'
import { useAccount } from '~publish/legacy/accountContext'

import { WelcomeChecklist } from '../WelcomeChecklist'

import styles from './WelcomeChecklistContainer.module.css'

const WELCOME_CHECKLIST_START_DATE = new Date('2023-01-23T00:00:00.000Z')
const BEACON_BUTTON_WIDTH = 60

// This component serves as an additional wrapper to the WelcomeChecklist logic.
// Here we determine whether a user in the split group is eligible to see the checklist.
const WelcomeChecklistContainer = (): JSX.Element => {
  const { account } = useAccount()
  const [rightDistance, setRightDistance] = useState(getBeaconDistance())

  const isOneBufferOrganization =
    account?.currentOrganization?.isOneBufferOrganization
  const orgCreatedAt =
    new Date(account?.currentOrganization?.createdAt || '2000-01-01') || null
  const isLaunchEligible = orgCreatedAt > WELCOME_CHECKLIST_START_DATE || false

  useOnBeaconReady(useCallback(() => setRightDistance(getBeaconDistance()), []))

  return (
    <div
      className={styles.container}
      style={
        {
          '--beacon-right-distance': `${rightDistance}px`,
        } as React.CSSProperties
      }
    >
      {isOneBufferOrganization && isLaunchEligible && <WelcomeChecklist />}
    </div>
  )
}

export default WelcomeChecklistContainer

function getBeaconDistance(): number {
  const offset = window.Beacon?.('info')?.config?.display?.horizontalOffset
  return offset ? offset + BEACON_BUTTON_WIDTH : 0
}

function useOnBeaconReady(callback: () => void): void {
  useEffect(() => {
    const checkBeacon = (): void => {
      if (window.Beacon) {
        window.Beacon('once', 'ready', callback)
      } else {
        // If Beacon isn't available yet, check again after a short delay
        setTimeout(checkBeacon, 100)
      }
    }

    checkBeacon()
    return () => {
      window.Beacon?.('off', 'ready', callback)
    }
  }, [callback])
}
