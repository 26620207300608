import React from 'react'
import type { Checklist } from '~publish/legacy/welcome-checklist/types'
import {
  Text,
  CheckIcon,
  ChevronRightIcon,
  Button,
  Flex,
} from '@buffer-mono/popcorn'
import styles from './ChecklistBody.module.css'

const ChecklistBody = ({
  checklist,
  handleChecklistItemClick,
}: {
  checklist: Checklist
  handleChecklistItemClick: (
    id: number,
    description: string,
    isCompleted: boolean,
  ) => void
}): JSX.Element => {
  return (
    <div className={styles.body}>
      {checklist.items.map((item) => {
        return (
          <Button
            variant="tertiary"
            key={item.description}
            onClick={(): void => {
              handleChecklistItemClick(
                item.id,
                item.description,
                item.isCompleted,
              )
            }}
            data-state={item.isCompleted ? 'completed' : 'not-completed'}
            className={styles.item}
          >
            <Flex gap="sm" align="center">
              <div className={styles.check}>
                {item.isCompleted && (
                  <CheckIcon size="small" color="inverted" />
                )}
              </div>
              <Text className={styles.text}>{item.description}</Text>
            </Flex>
            <ChevronRightIcon size="small" color="subtle" />
          </Button>
        )
      })}
    </div>
  )
}

export default ChecklistBody
