import React from 'react'
import { Text, Link } from '@buffer-mono/popcorn'
import styles from './ChecklistFooter.module.css'

const ChecklistFooter = (): JSX.Element => {
  return (
    <footer className={styles.footer}>
      <Text as="p" color="subtle" size="sm">
        Need some help?{' '}
        <Link
          href="https://support.buffer.com/article/600-getting-started-with-buffers-publishing-features"
          external
          className={styles.link}
        >
          Read our guide
        </Link>
      </Text>
    </footer>
  )
}

export default ChecklistFooter
