import React from 'react'
import {
  IconButton,
  Text,
  Progress,
  CloseIcon,
  Flex,
  Heading,
} from '@buffer-mono/popcorn'
import type {
  Checklist,
  ChecklistProgress,
} from '~publish/legacy/welcome-checklist/types'
import styles from './ChecklistHeader.module.css'

const ChecklistHeader = ({
  checklist,
  checklistProgress,
  onDismissWelcomeChecklist,
}: {
  checklist: Checklist
  checklistProgress: ChecklistProgress
  onDismissWelcomeChecklist: () => void
}): JSX.Element => {
  const shouldShowProgressBar = checklistProgress.progressPercent > 0
  return (
    <div className={styles.header}>
      <Flex justify="between">
        <Heading as="h2" size="xsmall">
          {checklist.title}
        </Heading>
        <IconButton
          label="Dismiss Welcome Checklist"
          onClick={onDismissWelcomeChecklist}
          variant="tertiary"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Flex>
      {shouldShowProgressBar && (
        <Flex align="center" gap="xs">
          <Text className={styles.progressBarText}>
            {checklistProgress.itemsCompleted} of {checklistProgress.itemsTotal}
          </Text>
          <Progress
            color="brand"
            className={styles.progressBar}
            value={checklistProgress.progressPercent}
          />
        </Flex>
      )}
    </div>
  )
}

export default ChecklistHeader
